/* font.css */
@font-face {
  font-family: "HelveticaLTPro-Bold";
  src: url("/public/font/HelveticaLTPro-Bold.woff") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "HelveticaLTPro-Light";
  src: url("/public/font/HelveticaLTPro-Light.woff") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "HelveticaLTPro-Roman";
  src: url("/public/font/HelveticaLTPro-Roman.woff") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 12px;
}

body{
  touch-action: none;
}

input {
  font-family: "HelveticaLTPro-Light";
}

.input-placeholder::placeholder {
  font-family: "HelveticaLTPro-Light";
}

body {
  touch-action: none;
}

/* floating */
input[type="text"]::-webkit-input-placeholder {
  color: #c4c4c4;
  font-family: "HelveticaLTPro-Roman";
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.leaderBroad * {
  color: #fdf4cf !important;
}
