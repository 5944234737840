p {
  font-size: 1rem;
  color: white;
  text-align: center;
  font-family: "HelveticaLTPro-Roman";
}

h1 {
  font-size: 1.9rem;
  color: white;
  text-align: center;
  font-family: "HelveticaLTPro-Bold";

  filter: drop-shadow(0, 0, 0.75rem, #000000);
}

h2 {
  font-size: 1.6rem;
  color: white;
  text-align: center;
  font-family: "HelveticaLTPro-Bold";
}

h3 {
  font-size: 2.7rem;
  color: white;
  text-align: center;
  font-family: "HelveticaLTPro-Bold";
}

h4 {
  font-size: 1.8rem;
  color: white;
  text-align: center;
  font-family: "HelveticaLTPro-Bold";
}

h5 {
  font-size: 1.3rem;
  color: white;
  text-align: center;
  font-family: "HelveticaLTPro-Bold";
}

h6 {
  font-size: 1.2rem;
  color: white;
  text-align: center;
  font-family: "HelveticaLTPro-Light";
}

.dontwantBold p {
  font-family: "HelveticaLTPro-Roman";
}

.imgContainer img {
  height: 100%;
}

.imgContainer {
  width: fit-content;
}

.dottedLine {
  content: "";
  position: absolute;
  width: 60%; /* Adjust based on your design */
  height: 0;
  border-top: 0.1rem dashed #fff;
  top: 50%; /* Adjust to position above the range slider */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

input[type="range"] {
  -webkit-appearance: none; /* This is necessary to apply custom styles in Chrome */
  background-color: transparent;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none; /* This is necessary to apply custom styles in Chrome */
  height: 2rem;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* This is necessary to apply custom styles in Chrome */
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background: #ffffff; /* Add a background color so the thumb is visible */
}

input[type="range"] {
  background-color: transparent !important;
}

.dot {
  width: 0.2rem;
  height: 0.2rem;
  background-color: white;
}

.circle {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid white;
}

.spinner {
  border-radius: "50%";
  animation: spin 8s linear infinite;
}

/* spin animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}